<template>
  <section class="wrap">
    <img class="profile-picture" src="@/assets/logo.png" alt="Profile" />
  </section>
</template>

<script>
export default {
  name: 'ProfilePicture',
  props: {},
  methods: {},
}
</script>

<style scoped lang="scss">
.wrap {
  // position: absolute;
  // top: 0;
  // background: var(--color-muted);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // height: 154px;
  // width: 114px;
  // margin-top: -77px;
  // margin-left: 20px;
  // border: 5px solid white;
  // box-shadow: inset 0 5px 10px #0001, 0px 3px 12px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
}
.profile-picture {
  object-fit: cover;
  object-position: center;
  border-radius: 100px;
  height: 100%;
  width: 100%;
}
</style>
