<template>
  <div class="profile-wrapper">
    <transition name="dropdown-l" appear>
      <router-link
        id="world-map"
        class="profile-header app-negative-margin-top"
        to="/profile/map"
      >
        <img
          src="@/assets/world-map.png"
          class="profile-header-map"
          alt="World map"
        />
      </router-link>
    </transition>
    <transition name="dropdown" appear>
      <div
        class="profile-card-wrapper container-xs"
        style="transition-delay: 100ms"
      >
        <div v-if="user" class="profile-card">
          <section class="profile-picture-wrapper">
            <ProfilePicture class="profile-picture" />
          </section>
          <button
            class="profile-text-button"
            @click="$router.push('/profile/edit')"
          >
            {{ $t('profile.edit-profile') }}
            <icon icon="Edit" />
          </button>
          <p class="app-title" style="margin-top: 1rem">
            {{ getFullName(user) }}
          </p>
          <div class="profile-actions">
            <RouterLink class="app-card-clickable" to="/profile/companions">
              <IconPassengers />
              <p>Travelers</p>
            </RouterLink>
            <RouterLink class="app-card-clickable" to="/profile/payments">
              <IconCard />
              <p>Payment</p>
            </RouterLink>
            <RouterLink class="app-card-clickable" to="/profile/map">
              <IconSuitcase />
              <p>Trips</p>
            </RouterLink>
          </div>
          <div class="profile-info">
            <div
              v-for="{ icon, text } in userInfoCards"
              class="app-card"
              style="display: flex; align-items: center; padding: 1rem"
            >
              <component :is="icon" v-if="icon" height="100%" width="14px" />
              <p style="margin-left: auto; font-family: DDin">{{ text }}</p>
            </div>
          </div>
          <button
            id="logout-button"
            class="profile-text-button"
            @click="$router.push('/logout')"
          >
            {{ $t('settings.logout') }}
            <icon icon="Logout" />
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SettingsCards from '@/components/common/SettingsCards.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import IconApple from '../../components-rf/icons/socials/IconApple.vue'
import IconCard from '../../components-rf/icons/payments/IconCard.vue'
import IconSuitcase from '../../components-rf/icons/bookings/IconSuitcase.vue'
import IconPassengers from '../../components-rf/icons/common/IconPassengers.vue'
import IconPhone from '../../components-rf/icons/common/IconPhone.vue'
import IconMail from '../../components-rf/icons/common/IconMail.vue'
import IconHome from '../../components-rf/icons/common/IconHome.vue'
import ProfilePicture from '../../components/common/ProfilePicture.vue'
import { AUTH_PROVIDERS } from '../../constants/AUTH_PROVIDERS'
import { appAnalytics } from '../../services/analytics/analytics'
import { displayUserLocation } from '../../services/LocationService'

export default {
  name: 'Profile',
  components: {
    SettingsCards,
    ProfilePicture,
    IconApple,
    IconCard,
    IconSuitcase,
    IconPassengers,
    IconPhone,
    IconHome,
    IconMail,
  },
  created() {
    this.getUser()
    this.getCompletedBookings()
  },
  mounted() {
    appAnalytics.page({ title: 'profile_screen_view' })
  },
  data() {
    return {
      AUTH_PROVIDERS,
      statData: {
        trips: 0,
        miles: 0,
        countries: 0,
      },
    }
  },
  methods: {
    ...mapActions('UserModule', ['addSocial', 'getUser']),
    ...mapActions('BookingModule', ['getCompletedBookings']),
  },
  computed: {
    ...mapState('UserModule', ['user', 'travelStats']),
    ...mapGetters('UserModule', ['userLocation']),
    ...mapGetters('TitleModule', ['getFullName']),
    userInfoCards() {
      const data = []
      const user = this.user
      if (user.phone && user.phoneCountry)
        data.push({
          icon: IconPhone,
          text: `${user.phoneCountry.dialCode} ${user.phone}`,
        })
      if (this.userLocation)
        data.push({
          icon: IconHome,
          text: displayUserLocation(this.userLocation),
        })
      if (!parseInt(user.email)) data.push({ icon: IconMail, text: user.email })
      data.push(...this.userSocialCards)

      return data
    },
    userSocialCards() {
      const socials = this.user.socialsList
      return AUTH_PROVIDERS.map(({ id, icon }) => {
        const savedSocial = socials.find(el => el.provider === id)
        return {
          icon,
          text: !savedSocial
            ? this.$t('profile.social-account-not-connected')
            : savedSocial?.fallbackField ??
              this.$t('profile.social-account-added'),
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.profile-wrapper {
  position: absolute;
  width: 100%;
}
.profile-header {
  background: var(--color-secondary);
  height: 200px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  place-content: center;
}
.profile-header-map {
  height: 300px;
  margin-top: -50px;
}
.profile-card-wrapper {
  position: relative;
  margin-top: -25px;
  padding-bottom: 6rem;
}
.profile-card {
  background: white;
  padding: 2rem;
  box-shadow: var(--shadow-app);
  border-radius: 25px;
  display: grid;
  gap: 2rem;
}
.profile-text-button {
  font-family: DDin;
  text-decoration: underline;
  text-underline-offset: 2px;
  display: grid;
  align-items: center;
  gap: 0.5rem;
  grid-template-columns: auto 1fr;
  margin-left: auto;
  color: #444;
}
.profile-picture-wrapper {
  position: absolute;
  top: 0;
  height: 154px;
  width: 114px;
  margin-top: -77px;
  margin-left: 20px;
}
.profile-picture {
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
}
.profile-actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  color: #444;
  & > * {
    display: grid;
    gap: 10px;
    justify-items: center;
    grid-template-rows: 1fr auto;
    align-items: center;
  }
  & p {
    font-family: DDin;
  }
}
.profile-info {
  & > * {
    border-radius: 0px;
    --border-radius: 10px;
    position: relative;
    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }
    &:last-child {
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #eee;
      }
    }
  }
}
.profile-details {
  margin-top: 0.5rem;
  & > p {
    display: grid;
    gap: 1rem;
    padding: 0.5rem;
    grid-template-columns: auto 1fr;
    border-top: 1px dashed var(--color-grey);
    word-break: break-all;
    &:last-child {
      border-bottom: 1px dashed var(--color-grey);
    }
  }
}
.x-stickers {
  margin-top: 1rem;
  box-shadow: var(--shadow-app);
  border-radius: var(--border-radius-m);
  background: white;
}
.x-sticker-icons-wrap {
  padding: 1rem;
}
.x-sticker-icons {
  margin-left: auto;
  margin-right: auto;
  width: 280px;
  margin-bottom: -5px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  & > div {
    height: 100px;
    width: 100px;
    background: var(--color-dark);
    margin-bottom: 5px;
    flex: 0 0 auto;
  }
}

.x-sticker-button {
  padding: 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: auto auto;
}
.profile-section {
  margin-top: 3rem;
}
</style>
